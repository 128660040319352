@import url('https://fonts.cdnfonts.com/css/georgia');

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  display: flex;
  overflow: hidden;
  padding: 50px 0 50px 0;
  width: 100%;
}

.logo-slide {
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;
  animation: 15s slide infinite linear;
}

.logo-slide img {
  margin: 0 40px;
}

.section-header {
  font-size: 52px;
  font-weight: bolder;
}
.auto-class {
  font-weight: 200;
}

.take-charge {
  font-weight: bolder;
  font-size: 48px;
}
.text-center {
  text-align: start;
}
@media screen and (max-width: 1023px) {
  .section-header {
    font-size: 40px;
  }

  .take-charge {
    font-size: 36px;
  }
}

@media screen and (max-width: 991px) {
  .take-charge {
    text-align: center;
  }
}

@media screen and (max-width: 760px) {
  .section-header {
    font-size: 32px;
  }
  .take-charge {
    font-size: 32px;
  }
}
.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns */
  gap: 20px; /* Gap between cards */
}

.card {
  background-color: lightblue;
  padding: 20px;
}

.big-card {
  grid-column: span 2;
}

@media screen and (max-width: 1023px) {
  .section-header {
    font-size: 40px;
  }
  .text-center {
    text-align: center;
  }
  .card-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 480px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000;
}

.drawer.open {
  transform: translateX(0);
}

.drawer-content {
  padding: 1rem;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}
.finance {
  font-size: 51px;
  font-style: italic;
  font-weight: 100;
}

@media screen and (max-width: 479px) {
  .finance {
    font-size: 30px;
  }
}

/* scrolling card */

.scrolling-card-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scrolling-card {
  display: flex;
  overflow-x: auto;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.card-content {
  flex: 0 0 auto;
  width: 260px;
  margin: 8px;

  background-color: #f0f0f0;
  border-radius: 8px;
  scroll-snap-align: start;
}

/* .scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
} */

@media (max-width: 650px) {
  .scrolling-card {
    scroll-snap-type: x mandatory;
  }

  .card-content {
    scroll-snap-align: center;
    width: 90%;
  }

  .scroll-button {
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .card-content {
    width: 85%;
  }
}

::-webkit-scrollbar {
  display: none;
}

